import React, { useEffect, useState, useCallback } from 'react';
import WelcomeModal from '../welcomeModal/WelcomeModal';
import axios from 'axios';
import comments from '../../assets/comments.json';
import './imageLoader.css';

export default function ImageLoader({ today: initialDate }) {
    const [date, setDate] = useState(formatDateYYYYMMDD(initialDate));
    const [message, setMessage] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [showWelcome, setShowWelcome] = useState(getShowWelcome(initialDate));
    const [isFirstWelcome, setIsFirstWelcome] = useState(true);

    const noImageFoundError = 'Parece ser que nuestros ositos ingenieros no encuentran la imagen de este día... 😢';
    const noMessageFoundError = 'Parece ser que nuestros ositos ingenieros están ocupados y no pueden buscar el mensaje de este día... 😢';
    const futureDateError = 'Nuestros ositos ingenieros no permitirán que veas las fotos antes de tiempo... 🤭';

    const getDayComment = useCallback((newDate) => {
        const foundComment = comments.find(comment => comment.date === newDate);
        if (!foundComment || !foundComment.comment) {
            return noMessageFoundError;
        }
        return foundComment.comment;
    }, [noMessageFoundError]);

    const fileExists = useCallback(async (path) => {
        try {
            await axios.get(path);
            return true;
        } catch (error) {
            return false;
        }
    }, []);

    const getRandomErrorImage = useCallback((selectedDate) => {
        const min = 1;
        const max = 19;
        const today = new Date(selectedDate);
        const seed = today.getDate() + today.getMonth() + today.getFullYear();
        const randomnum = (seed % max) + min;
        return `assets/errors/FunnyBear${randomnum}.jpeg`;
    }, []);

    const getImagePath = useCallback(async (newDate) => {
        setShowWelcome(getShowWelcome(newDate));
        if (newDate > formatDateYYYYMMDD(initialDate)) {
            setMessage(futureDateError);
            return 'assets/errors/BadDate.jpg';
        }

        const path = `assets/${newDate}.jpeg`;
        if (!(await fileExists(path))) {
            setMessage(noImageFoundError);
            return 'assets/errors/NoImage.jpeg';
        }

        const dayComment = getDayComment(newDate);
        setMessage(dayComment);
        if (dayComment === noMessageFoundError) {
            return getRandomErrorImage(newDate);
        }
        return path;
    }, [initialDate, fileExists, futureDateError, noImageFoundError, getDayComment, getRandomErrorImage]);

    useEffect(() => {
        const fetchData = async () => {
            const path = await getImagePath(date);
            setImagePath(path);
        };
        fetchData();
    }, [date, getImagePath]);

    const submitDate = async (newDate) => {
        if (newDate) {
            setDate(newDate);
            setImagePath(await getImagePath(newDate));
        }
    };

    const addDays = (inputDate, days) => {
        const newDate = new Date(inputDate);
        newDate.setDate(newDate.getDate() + days);
        return formatDateYYYYMMDD(newDate);
    };

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowLeft') {
            submitDate(addDays(date, -1));
        } else if (event.key === 'ArrowRight') {
            submitDate(addDays(date, 1));
        } else if (event.key.toLowerCase() === 'h') {
            submitDate(formatDateYYYYMMDD(new Date()));
        }
    }, [date, submitDate]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function formatDateYYYYMMDD(newDate) {
        const year = newDate.getFullYear();
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getShowWelcome(newDate) {
        return newDate === '2023-12-24';
    }

    function closeWelcomeModal() {
        setShowWelcome(false);
        setIsFirstWelcome(false);
    }

    return (
        <div id='imageLoader' className='container'>
            {(showWelcome && isFirstWelcome) && <WelcomeModal closeWelcomeModal={closeWelcomeModal} />}
            <div id="dateSelector" className="row">
                <div className="col col-md-4"></div>
                <div className="col col-md-4">
                    <form>
                        <div id="dateInputDiv" className="form-group">
                            <input type="date" className="form-control" placeholder="Fecha..." value={date} onChange={e => submitDate(e.target.value)} alt='Not found' />
                        </div>
                        <div id="dateSwitch" className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="dateSwitchButton btn btn-light" onClick={() => submitDate(addDays(date, -1))}>⬅️</button>
                            <button type="button" className="dateSwitchButton btn btn-light" onClick={() => submitDate(addDays(date, 1))}>➡️</button>
                        </div>
                    </form>
                </div>
                <div className="col col-md-4"></div>
            </div>
            <div className="row">
                <div className="col col-md-4"></div>
                <div className="col col-md-4">
                    <div className="galleryContainer">
                        <div className="gallery">
                            <img className="image" src={imagePath} alt="Daily" />
                            <div className="desc">{message}</div>
                        </div>
                    </div>
                </div>
                <div className="col col-md-4"></div>
            </div>
        </div>
    );
}
